import React from "react"
import Helmet from "react-helmet"

const AllSizedHelmet = React.memo(
	props => {
		return (
			<Helmet defaultTitle="Автоматизация ресторанов и ресторанного бизнеса – программа складского учёта для общепита с кассой на планшете – Quick Resto">
				<html lang="ru" />
				<script type="text/javascript">
					{`
                  (function(w,d,v3){
                  w.chaportConfig = {
                  appId : '629f45493485b852a2215156'
                  };

                  if(w.chaport)return;v3=w.chaport={};v3._q=[];v3._l={};v3.q=function(){v3._q.push(arguments)};v3.on=function(e,fn){if(!v3._l[e])v3._l[e]=[];v3._l[e].push(fn)};var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://app.chaport.com/javascripts/insert.js';var ss=d.getElementsByTagName('script')[0];ss.parentNode.insertBefore(s,ss)})(window, document);
                `}
				</script>
				{props.children}
			</Helmet>
		)
	},
	// React.memo не запоминает props, если это props.children. Поэтому необходимо сравнить props'ы вручную, для чего
	// вторым аргументом передаётся функция сравнения предыдущих и последующих props'ов, которая возвращает true, если
	// предыдущие props равны следующим.
	// В нашем случае props равны всегда, т.к. в компонент AllSizedHelmet всегда передаются одни и те же props.children
	// т.е. сравнивающая props'ы функция всегда должна возвращать true
	() => {
		return true
	}
)

export default AllSizedHelmet
